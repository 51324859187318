body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #998f76;
}

::-webkit-scrollbar-thumb {
  background-color: #482714;
  border-radius: 12px;
}

button a {
  text-decoration: none;
  color: inherit;
}

input:-webkit-autofill {
  background-color: transparent !important;
  box-shadow: 0 0 0 50px transparent inset;
  -webkit-box-shadow: 0 0 0 50px #DDD6C5 inset;
  -webkit-text-fill-color: black;
}